<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Allergens</div>
    </div>
    <div class="card-body">
      <div
        v-for="allergen in recipeAllergens"
        :key="allergen"
        class="tag mr-2 mb-1">
        {{allergen}}
      </div>
      <div v-if="!recipeAllergens.length" class="list-group-item">No allergens found</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RecipeAllergensCard',
  props: {
    ingredients: {
      type: Array,
      required: true,
    },
  },
  computed: {
    recipeAllergens() {
      return [...new Set(this.ingredients.flatMap(article => article.allergens).map(allergen => allergen.name))];
    },
  },
};
</script>

<style scoped>
.list-group {
  max-height: 10.5rem;
  overflow-y: auto;
}
</style>
