<template>
  <span v-if="icon">
    <a
      v-if="url && published"
      :href="renderUrl(2)"
      class="fe fe-download"
      target="_blank"
      title="Download card"
      rel="noopener noreferrer"/>
    <a
      v-else
      tabindex="0"
      class="fe fe-eye"
      title="Preview card"
      @click="download(2)"/>
  </span>
  <div v-else class="dropdown mr-2">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      id="DownloadCardBtn">
      {{buttonText}}
    </button>
    <div class="dropdown-menu" aria-labelledby="DownloadCardBtn">
      <template v-for="item in numberOfPeople">
        <template v-if="url">
          <a
            :key="item"
            class="dropdown-item"
            :href="renderUrl(item)"
            target="_blank"
            rel="noopener noreferrer">
            {{item ? `${item} people` : 'Classic'}}
          </a>
        </template>
        <template v-else>
          <button
            :key="item"
            type="button"
            class="dropdown-item"
            :disabled="disabled"
            @click="download(item)">
            {{item ? `${item} people` : 'Classic'}}
          </button>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {recipes} from '@/services';

export default {
  name: 'DownloadRecipeCard',
  props: {
    recipeId: {
      required: true,
      type: Number,
    },
    url: {
      required: false,
      type: String,
    },
    published: {
      required: false,
      type: Boolean,
    },
    icon: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      tooltip: 'View recipe card in PDF format',
      numberOfPeople: [0, 2, 3, 4],
    };
  },
  computed: {
    buttonText() {
      return this.url ? 'PDF card' : 'Preview card';
    },
    renderUrl() {
      return people => {
        let endpoint = this.url;
        if (people) {
          endpoint += '?number_of_people=' + people;
        }
        return endpoint;
      };
    },
  },
  methods: {
    async download(people) {
      // We have to create the popup window immediately as a popup blocker will block it after the async operation.
      const tab = window.open('/', '_blank');
      const response = (await recipes.downloadById(this.recipeId, people)).data;
      tab.location.replace(URL.createObjectURL(response));
    },
  },
};
</script>
