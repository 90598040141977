<template>
  <div class="card review-card">
    <div class="card-body">
      <h5 class="card-title">{{reviewTypeHeader}}</h5>
      <!-- Input of type checkbox -->
      <template v-if="review.type === 'checkbox' && review.list.length > 0">
        <div class="review-content-container">
          <selection-card
            :caption="review.field_name"
            :items="review.list"
            :label="value => value.name"
            :selected="value => changes.some(a => a.id === value.id)"
            :total-selected="changes.length"
            :filter="true"
            :withFooter="false"
            :withHeader="false"
            @change="toggleArray(changes, $event.item, (a, b) => a.id === b.id)"
          ></selection-card>
        </div>
      </template>
      <!-- Input of type tags -->
      <template v-else-if="review.type === 'tags'">
        <div class="mb-4">
          <selectize
            v-model="changes"
            class="form-control custom-select selectized h-100"
            multiple>
            <option :value="null">Choose protein sub-categories</option>
            <option
              v-for="tag in review.list"
              :key="tag.id"
              :value="tag.id">
              {{tag.name}}
            </option>
          </selectize>
        </div>
      </template>

      <!-- input of type select -->
      <template v-else-if="review.type === 'select'">
        <select
          v-model="changes"
          class="form-control custom-select mb-4 test"
        >
          <option :value="null">Select {{review.fieldName}}</option>
          <option
            v-for="item in review.list"
            :key="item.key"
            :value="item">
            {{item.name}}
          </option>
        </select>
      </template>

      <!-- Input of type text -->
      <template v-else-if="review.type === 'text'">
        <div class="review-content-container">
          <textarea
            v-model="changes"
            :readonly="viewOnly"
            class="card-content form-control"
            @keypress="setTextAreaChanges"
          ></textarea>
        </div>
      </template>
      <h5 class="card-title">Notes</h5>
      <textarea
        v-model="notes"
        :readonly="viewOnly"
        class="card-content form-control"
      >
      </textarea>
    </div>
    <div class="card-footer">
      <template v-if="viewOnly">
        <dimmer :active="loading">
          <button
            type="button"
            class="btn btn-success float-right ml-4"
            @click="accept"
          >
            Accept
          </button>
          <button
            type="button"
            class="btn btn-danger float-right"
            @click="reject"
          >
            Reject
          </button>
        </dimmer>
      </template>
      <template v-else>
        <button
          type="button"
          class="btn btn-primary float-right"
          @click="submit"
        >
          Done
        </button>
      </template>
    </div>
  </div>
</template>

<script>

import SelectionCard from '@/components/SelectionCard';
import cloneDeep from 'lodash/cloneDeep';
import {recipeReviews} from '@/services';

const STATUS_ACCEPTED = 'accepted';
const STATUS_REJECTED = 'rejected';

export default {
  components: {

    SelectionCard,
  },
  props: {
    viewOnly: {
      type: Boolean,
      default: false,
    },
    reviewkey: {
      type: Number,
      default: 0,
    },
    review: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const {changes, notes} = this.review;

    return {
      changes: typeof changes === 'object' ? cloneDeep(changes) : changes,
      notes,
      loading: false,
    };
  },
  computed: {
    reviewTypeHeader() {
      if (this.review?.action === 'deleted') return 'Delete Item';
      if (this.review?.action === 'new') return 'Add new Item';

      // fallback
      return 'Suggested changes';
    },
  },
  methods: {
    accept() {
      this.callApi(STATUS_ACCEPTED);
    },
    reject() {
      this.callApi(STATUS_REJECTED);
    },
    async callApi(status) {
      this.loading = true;
      try {
        await recipeReviews.patch({id: this.review.id, status});
        this.$emit(status);
      }
      catch (ex) {
        this.$toasted.error(`Review could not be ${status}`);
      }
      finally {
        this.loading = false;
      }
    },
    submit() {
      if (this.review.type === 'tags') {
        if (Array.isArray(this.changes)) {
          this.changes = this.changes.map(change => parseInt(change));
        }
        else {
          this.changes = [parseInt(this.changes)];
        }
      }
      this.$emit('submit', {changes: this.changes, notes: this.notes, key: this.reviewkey});
    },
    setTextAreaChanges(event) {
      // Don't allow the user to press the enter key inside the text-area
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    },
  },
};

</script>

<style>

    .review-card {
      width: 93%;
      position: relative;
      left: 3%;
      max-height: 500px;
      overflow-y: scroll;
    }

    .review-content-container {
      max-height: 260px;
      overflow: scroll;
    }

    .card-content {
        width: 100%;
        min-height: 100px;
        margin-bottom: 20px;
    }
</style>
