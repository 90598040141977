<template>
  <b-modal
    v-model="isShowModal"
    title="Swap Ingredient"
    size="md"
    no-close-on-backdrop
    no-close-on-esc
    @close="() => $emit('close')">
    <template slot="modal-header-close"><wbr/></template>

    <!-- Recipe Section -->
    <div class="recipe-list">
      <img
        class="recipe-img"
        :src="imgSrc"
        :alt="swapRecipe.name"/>
      <p class="recipe-name">{{swapRecipe.name}}</p>
    </div>

    <!-- Search Input -->
    <form
      method="POST"
      class="swap-form"
      id="swapIngredientForm"
      @submit.prevent="submitSwapIngredients">
      <div class="input-group swap-ingredient-container">
        <label for="swap-from" class="form-label swap-from-label">Swap From</label>
        <input
          v-model="$v.swapFrom.$model"
          class="form-control swap-from-input"
          type="text"
          name="swap-from"
          placeholder="Ingredient getting swapped">
        <template v-if="$v.swapFrom.$dirty">
          <div v-if="!$v.swapFrom.required" class="invalid-feedback d-block swap-from-error">
            Swap From field is required
          </div>
          <div v-if="!$v.swapFrom.maxLength" class="invalid-feedback d-block swap-from-error">
            Character limit is 50
          </div>
        </template>
        <img
          v-svg-inline
          class="swap-arrow"
          src="@/assets/svg/arrow-left-right.svg"
          alt="recipe swap arrow"/>
        <label for="swap-to" class="form-label swap-to-label">Swap To</label>
        <input
          v-model="$v.swapTo.$model"
          class="form-control swap-to-input"
          type="text"
          name="swap-to"
          placeholder="New Ingredient">
        <template v-if="$v.swapTo.$dirty">
          <div v-if="!$v.swapTo.required" class="invalid-feedback d-block swap-to-error">
            Swap To field is required
          </div>
          <div v-if="!$v.swapTo.maxLength" class="invalid-feedback d-block swap-to-error">
            Character limit is 50
          </div>
        </template>
        <div v-if="!$v.swapFrom.notSameAs" class="invalid-feedback d-block swap-error">
          Swap From text cannot be the same as Swap To text
        </div>
      </div>
    </form>

    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="() => $emit('close')">
        Cancel
      </button>
      <button
        form="swapIngredientForm"
        type="submit"
        class="btn btn-primary">
        Apply
      </button>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from 'bootstrap-vue';
import {required, maxLength} from 'vuelidate/lib/validators';

export default {
  name: 'RecipesSwapIngredientModal',
  components: {
    BModal,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    swapRecipe: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swapFrom: '',
      swapTo: '',
    };
  },
  computed: {
    isShowModal: {
      get: function() { return this.isShow; },
      set: function(newValue) {
        this.$emit('show', newValue);
      },
    },
    imgSrc() {
      return this.swapRecipe.photo && this.getTransformationUrl(this.swapRecipe.photo, {width: 42, height: 42, crop: 'fill'});
    },
  },
  created() {
    this.swapFrom = this.swapRecipe.swapFrom;
    this.swapTo = this.swapRecipe.swapTo;
  },
  validations: {
    swapFrom: {
      required,
      type: String,
      maxLength: maxLength(50),
      notSameAs: function() {
        if (!this.swapFrom?.length) {
          return true;
        }
        return this.swapFrom?.toLowerCase() !== this.swapTo?.toLowerCase();
      },
    },
    swapTo: {
      required,
      type: String,
      maxLength: maxLength(50),
    },
  },
  methods: {
    submitSwapIngredients() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit('apply', this.swapFrom, this.swapTo);
      }
    },
  },
};
</script>

<style scoped>
.recipe-list {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.recipe-img {
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 50%;
}

.recipe-name {
  margin: 0;
  margin-left: 12px;
  font-size: 15px;
  letter-spacing: -0.02em;
}

.swap-arrow {
  width: 20px;
  height: 20px;

  grid-column: 2/3;
  grid-row: 2/3;

  align-self: center;
  justify-self: center;

  fill: #4A5157;
}

.swap-ingredient-container {
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  grid-template-rows: 0.5fr 1fr 0.5fr;
}

.swap-from-label {
  grid-column: 1/2;
  grid-row: 1/2;
}

.swap-from-input {
  grid-column: 1/2;
  grid-row: 2/3;

  width: 100%;
}

.swap-from-error {
  grid-column: 1/2;
  grid-row: 3/4;
}

.swap-to-label {
  grid-column: 3/4;
  grid-row: 1/2;
}

.swap-to-input {
  grid-column: 3/4;
  grid-row: 2/3;

  width: 100%;
}

.swap-to-error {
  grid-column: 3/4;
  grid-row: 3/4;
}

.swap-error {
  grid-column: 1/4;
  grid-row: 3/4;
}

.swap-from-input::placeholder, .swap-to-input::placeholder  {
  font-size: 14px;
}
</style>
