<template>
  <div class="card flex-0">
    <div class="card-header header-container">
      <h3 class="card-title">Themes</h3>
      <span class="badge badge-danger new-badge">NEW</span>
    </div>
    <div class="card-body d-flex flex-column gap-20">
      <div>
        <dimmer :active="themeLoader">
          <div class="d-flex flex-column gap-16">
            <div class="d-flex flex-column">
              <h6>Select a theme</h6>
              <autocomplete
                v-model="selectedTheme"
                class="w-100"
                :list.sync="themes"
                :nextPage="getThemes"
                placeholder="Select theme"
                :clearable="false"
                label="name"
                :disabled="!can(uiPermissions.RECIPE_SET_THEME)"/>
            </div>

            <div v-if="selectedTheme && can(uiPermissions.RECIPE_SET_THEME)" class="d-flex flex-column gap-4">
              <h6>Upload themed recipe images</h6>
              <div class="d-flex gap-16">
                <div class="d-flex flex-column gap-8">
                  <image-input
                    v-model="photoDesktop"
                    :tags="['recipe', 'plated shot']"
                    :preview.sync="photoDesktop"
                    :thumbnail-options="{height: 80, width: 80, crop: 'fill'}"
                    class="avatar theme-photo theme-photo-desktop"
                    @error="handleImageError"></image-input>
                  <p>Desktop Image </p>
                </div>
                <div  class="d-flex flex-column gap-8">
                  <image-input
                    v-model="photoMobile"
                    :tags="['recipe', 'plated shot']"
                    :thumbnail-options="{height: 80, width: 80, crop: 'fill'}"
                    :preview.sync="photoMobile"
                    class="avatar theme-photo theme-photo-mobile"
                    @error="handleImageError"></image-input>
                  <p>Mobile Image </p>
                </div>
              </div>
            </div>

            <div class="d-flex gap-8">
              <button
                v-if="selectedTheme && can(uiPermissions.RECIPE_SET_THEME)"
                type="button"
                class="btn btn-primary"
                @click="setTheme">
                Set theme
              </button>
              <button
                v-if="selectedTheme && can(uiPermissions.RECIPE_UNSET_THEME)"
                type="button"
                class="btn btn-danger"
                @click="unsetTheme">
                Unset theme
              </button>
            </div>
          </div>
        </dimmer>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@/components/Autocomplete';
import {recipes, themes} from '@/services';
import ImageInput from '@/components/ImageInput';

export default {
  name: 'ThemeConfiguration',
  components: {
    Autocomplete,
    ImageInput,
  },
  props: {
    recipeId: {
      required: true,
    },
    recipeTheme: {
      type: Object,
      required: false,
    },
    themePhotoDesktop: {
      type: Object,
      required: false,
    },
    themePhotoMobile: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      saving: false,
      themeLoader: false,
      selectedTheme: null,
      themes: [],
      photoDesktop: null,
      photoMobile: null,
    };
  },
  async created() {
    this.themes = await this.getThemes(1, '');
  },
  mounted() {
    this.selectedTheme = this.recipeTheme;
    this.photoDesktop = this.themePhotoDesktop;
    this.photoMobile = this.themePhotoMobile;
  },
  methods: {
    async getThemes(page, query) {
      this.themeLoader = true;
      try {
        const params = {
          limit: 15,
          page: page,
          query,
        };
        return themes.getByParameters(params);
      }
      catch (error) {
        console.error(error.response);
      }
      finally {
        this.themeLoader = false;
      }
    },
    async setTheme() {
      if (!this.selectedTheme) {
        return this.$toasted.error('Please select a theme.');
      }

      try {
        this.themeLoader = true;
        await recipes.setTheme(this.recipeId, this.selectedTheme.id, {
          themed_photo_desktop_id: this.photoDesktop?.id,
          themed_photo_mobile_id: this.photoMobile?.id,
        });
        return this.$toasted.success('Recipe theme set successfully.');
      }
      catch (error) {
        console.error(error.response);
        this.$toasted.error('Could not set the theme.');
      }
      finally {
        this.themeLoader = false;
      }
    },
    async unsetTheme() {
      const baseMessage = 'Are you sure you want to unset the theme?';
      const withPhotoMessage = 'This will also delete the uploaded photos.';

      const confirmMessage = this.photoDesktop || this.photoMobile
        ? `${baseMessage} ${withPhotoMessage}`
        : baseMessage;
      if (!confirm(confirmMessage)) return;

      try {
        this.themeLoader = true;
        await recipes.unsetTheme(this.recipeId);
        this.selectedTheme = null;
        this.resetThemeImages();
        return this.$toasted.success('Recipe theme unset successfully.');
      }
      catch (error) {
        console.error(error);
        this.$toasted.error('Could not unset the theme.');
      }
      finally {
        this.themeLoader = false;
      }
    },
    resetThemeImages() {
      this.photoDesktop = null;
      this.photoMobile = null;
    },
    handleImageError(ex) {
      this.$emit('photo-upload-error', ex);
    },
  },
};
</script>

<style scoped>
.card.flex-0 {
  flex-grow: unset;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-badge {
  padding: 6px;
  font-weight: bold;
}

.theme-photo {
  border-radius: 4px;
}

.theme-photo-desktop {
  width: 175px;
  height: 125px;
}

.theme-photo-mobile {
  width: 98px;
  height: 110px;
}
</style>
