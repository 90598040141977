<template>

  <div class="card">
    <div v-if="withHeader" class="card-header">
      <h3 class="card-title">{{caption}} ({{max ? `${totalSelected} of ${max}` : totalSelected}} selected)</h3>
      <div v-if="filter" class="card-options">
        <input
          v-model="query"
          type="text"
          class="form-control"
          placeholder="Filter"/>
      </div>
    </div>
    <div style="max-height: 25rem; overflow-y: scroll;">
      <table class="table table-vcenter text-nowrap card-table">
        <tbody>
          <tr v-for="(item, index) in shownItems" :key="item.id">
            <td>
              <div v-if="!readOnly" class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  force-disable
                  :checked="selected(item, index)"
                  :disabled="disabled(item, index)"
                  :id="`${identifier}_${item.id}`"
                  @change="$emit('change', {item})">
                <label :for="`${identifier}_${item.id}`" class="custom-control-label w-100">{{label(item, index)}}</label>
              </div>
              <template v-else>
                {{label(item, index)}}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="withFooter" class="card-footer text-right">
      <slot></slot>
    </div>
  </div>

</template>

<script>

import uniqueId from 'lodash/uniqueId';

export default {
  props: {

    caption: String,
    disabled: {default: () => false, type: Function},
    filter: {default: true},
    items: Array,
    label: Function,
    max: Number,
    readOnly: Boolean,
    selected: Function,
    totalSelected: Number,
    withHeader: {
      type: Boolean,
      default: true,
    },
    withFooter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {

      identifier: uniqueId('selection_card_'),
      query: '',
    };
  },

  computed: {

    shownItems() {
      return this.items.filter((item, index) => this.query === '' || this.label(item, index).split(' ').join('').toLowerCase().includes(this.query.split(' ').join('').toLowerCase()));
    },
  },
};

</script>
