<template>
  <a
    v-if="icon"
    v-b-tooltip="tooltip"
    :href="renderUrl(2)"
    class="fe fe-image mr-2"
    target="_blank"
    title="Quick preview"
    rel="noopener noreferrer"/>
  <div v-else class="dropdown mr-2">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      id="quickPreviewBtn">
      Quick preview
    </button>
    <div class="dropdown-menu" aria-labelledby="quickPreviewBtn">
      <template v-for="item in numberOfPeople">
        <a
          :key="item"
          class="dropdown-item"
          :href="renderUrl(item)"
          target="_blank"
          rel="noopener noreferrer">
          {{item ? `${item} people` : 'Classic'}}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuickPreviewRecipe',
  props: {
    url: {
      required: true,
      type: String,
    },
    icon: {
      default: false,
      type: Boolean,
    },
    printable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      tooltip: 'Fast recipe card preview in a non PDF format',
      numberOfPeople: [0, 2, 3, 4],
    };
  },
  computed: {
    renderUrl() {
      return people => {
        let endpoint = 'render?';
        if (this.printable) {
          endpoint += '&print=1';
        }
        if (people) {
          endpoint += '&number_of_people=' + people;
        }
        return this.url.replace('download', endpoint);
      };
    },
  },
};
</script>
