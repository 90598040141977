<template>
  <div v-click-outside="hideList" class="fuse-wrap">
    <input
      :value="value"
      :placeholder="placeholder"
      type="search"
      class="form-control"
      @input="evt => setValue(evt.target.value)"
      @click="handleShowList"
      @focus="handleShowList"/>

    <div
      v-if="value.length"
      class="clear"
      @click="clearSearch">
      <i class="fe fe-x"/>
    </div>

    <ul v-if="showList" class="fuse-list shadow-sm">
      <li v-if="value.length" @click="applySearch">
        <i class="fe fe-search mr-2"/>{{value}}
      </li>
      <li v-for="data in fuseList" :key="data.item.id">
        <a
          :href="`/recipes/${data.item.id}`"
          target="_blank"
          class="d-block">
          {{data.item.name}}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';

const defaultOptions = {
  minMatchCharLength: 2,
  threshold: 0.5,
  keys: ['name'],
};

export default {
  name: 'HFuseSearch',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search item',
    },
    list: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
    shouldShowList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fuse: null,
      fuseList: [],
      fuseOptions: {},
      showList: false,
    };
  },
  watch: {
    list: {
      handler() {
        this.fuse = new Fuse(this.list, this.fuseOptions);
        this.fuseList = this.fuse.search(this.value);
      },
      immediate: true,
    },
  },
  created() {
    this.fuseOptions = Object.assign({}, defaultOptions, this.options);
  },
  methods: {
    setValue(newValue) {
      this.$emit('input', newValue);

      this.debounceSearch(newValue);
    },
    debounceSearch: debounce(function(value) {
      this.fuseList = this.fuse.search(value);
    }, 300),
    hideList() {
      this.showList = false;
    },
    applySearch() {
      this.$emit('search');
      this.showList = false;
    },
    clearSearch() {
      this.setValue('');
      this.$emit('search');
      this.showList = false;
    },
    handleShowList() {
      this.shouldShowList && (this.showList = true);
    },
  },
};
</script>

<style scoped>
.fuse-wrap {
  position: relative;
  flex-grow: 1;
}

.fuse-list {
  padding: 0;
  width: 100%;
  max-height: 20rem;
  max-width: 100%;
  position: absolute;
  top: 2.3rem;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-top: 0;
  list-style-type: none;
  background: #fff;
  overflow-y: auto;
  z-index: 15;
}

.fuse-list li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.fuse-list li:hover {
  background-color: #e3f2fd;
}

.clear {
  width: 1rem;
  aspect-ratio: 1;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
