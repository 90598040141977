<template>
  <div v-if="marketItem" class="card">
    <div class="card-header">
      <h3 class="card-title">Market Item Information</h3>
    </div>
    <div class="card-body">
      <div class="form-group row d-flex">
        <label for="marketType" class="col-sm-3 col-form-label">Market Type</label>
        <div class="col-sm-9">
          <select
            v-model="marketType"
            class="form-control custom-select text-capitalize"
            required
            id="marketType">
            <option :value="null">Choose a market type</option>
            <option
              v-for="type in Object.values(MARKET_ITEM_TYPES)"
              :key="type"
              :value="type">
              {{type}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row d-flex">
        <label for="servingSize" class="col-sm-3 col-form-label">Serving Size</label>
        <div class="col-sm-9">
          <input
            v-model="servingSize"
            type="number"
            required
            class="form-control"
            id="servingSize">
        </div>
      </div>
      <div class="form-group row d-flex">
        <label for="unitPrice" class="col-sm-3 col-form-label">Unit Price <small>(excluding VAT)</small></label>
        <div class="col-sm-9">
          <input
            v-model.number="unitPrice"
            type="number"
            step="0.01"
            class="form-control"
            id="unitPrice">
        </div>
      </div>
      <div class="form-group row d-flex">
        <label for="vat" class="col-sm-3 col-form-label">VAT</label>
        <div class="col-sm-9">
          <input
            v-model.number="vat"
            type="number"
            step="0.01"
            class="form-control"
            id="vat">
        </div>
      </div>

      <div class="form-group row d-flex">
        <label for="totalPrice" class="col-sm-3 col-form-label">Total Price</label>
        <div class="col-sm-9">
          <input
            v-model.number="totalPrice"
            type="number"
            readonly
            class="form-control"
            id="totalPrice">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const MARKET_ITEM_TYPES = {
  DESSERT: 'dessert',
  BREAKFAST: 'breakfast',
  SIDE: 'side',
};

const DEFAULT_SERVING_SIZE = 4;

export default {
  name: 'MarketRecipeForm',
  props: {
    marketItem: {
      type: Object,
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MARKET_ITEM_TYPES,
    };
  },
  computed: {
    totalPrice() {
      return parseFloat(this.unitPrice) + parseFloat(this.vat);
    },
    marketType: {
      get() {
        return this.marketItem?.type;
      },
      set(value) {
        this.$emit('update:marketItem', {
          ...this.marketItem,
          type: value,
        });
      },
    },
    servingSize: {
      get() {
        return this.marketItem?.serving_size;
      },
      set(value) {
        this.$emit('update:marketItem', {
          ...this.marketItem,
          serving_size: value,
        });
      },
    },
    vat: {
      get() {
        return this.marketItem?.vat;
      },
      set(value) {
        this.$emit('update:marketItem', {
          ...this.marketItem,
          vat: value,
        });
      },
    },
    unitPrice: {
      get() {
        return this.marketItem?.unit_price;
      },
      set(value) {
        this.$emit('update:marketItem', {
          ...this.marketItem,
          unit_price: value,
        });
      },
    },
  },
  created() {
    if (this.isNew && !this.marketItem) {
      this.addMarketDefaults();
    }
  },
  methods: {
    addMarketDefaults() {
      this.$emit('update:marketItem', {
        type: MARKET_ITEM_TYPES.DESSERT,
        unit_price: 0,
        vat: 0,
        serving_size: DEFAULT_SERVING_SIZE,
      });
    },
  },
};
</script>
