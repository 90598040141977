<template>
  <div>
    <label class="form-label">
      <i v-b-tooltip="tooltip" class="fe mr-2 fe-info"></i>
      {{type}}
    </label>
    <div>
      <div
        v-for="tag in selectedTags"
        :key="tag"
        class="tag mr-2 mb-2">
        {{tagName(tag)}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoTags',
  props: {
    type: {
      type: String,
      required: true,
    },
    tagsList: {
      type: Array,
      required: true,
    },
    selectedTags: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tooltip() {
      return `${this.type} tags are automagically added based on the ingredients you select.`;
    },
  },
  methods: {
    // find the name of the tag by id
    tagName(id) {
      return this.tagsList.find(tag => tag.id === id).name;
    },
  },
};
</script>
