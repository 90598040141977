<template>
  <div>
    <b-modal
      v-model="showDialog"
      @ok="$emit('submit')"
    >
      <template slot="modal-header-close"><wbr/></template>
      Are you sure you want to submit and send your review
    </b-modal>
    <div class="card-options">
      <button
        type="button"
        class="btn btn-primary mr-2"
        @click="$emit('draft')"
      >
        Save Review as Draft
      </button>
      <button
        type="button"
        class="btn btn-success"
        @click="showDialog = true"
      >
        Submit Review
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showDialog: false,
    };
  },
};
</script>
