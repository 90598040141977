<template>
  <b-modal
    v-model="isShowModal"
    hide-footer
    title="Add Swap Recipe">
    <template slot="modal-header-close"><wbr/></template>
    <dimmer :active="listLoading">
      <div class="form-group mb-0" data-test="recipes-add-swap-modal">
        <!-- Search Input -->
        <form
          method="POST"
          class="search-form"
          @submit.prevent="fetchRecipes">
          <div class="input-group">
            <h-fuse-search
              :shouldShowList="false"
              :value="recipeSearchText"
              placeholder="Search"
              @input="setValue"
              @search="fetchRecipes"
            />
            <div class="input-group-append">
              <button class="btn btn-primary"><i class="fe fe-search"/></button>
            </div>
          </div>
        </form>
        <!-- Recipes Section -->
        <div class="recipe-section">
          <div
            v-for="recipe in items"
            :key="recipe.id"
            class="recipe-list-item"
            @click="() => $emit('add-swap', recipe)">
            <i class="fe fe-plus plus-icon"/>
            <img
              class="recipe-img"
              :src="getTransformationUrl(recipe.photo, {width: 42, height: 42, crop: 'fill'})"
              :alt="recipe.name"/>
            <p class="recipe-name">{{recipe.name}}</p>
          </div>
        </div>
      </div>
    </dimmer>
  </b-modal>
</template>

<script>
import {BModal} from 'bootstrap-vue';
import HFuseSearch from '@/components/ui/HFuseSearch';
import {recipes} from '@/services';
import list from '@/mixins/list';

const RECIPE_SWAP_LISTING_LIMIT = 5;

export default {
  name: 'RecipesAddSwapModal',
  components: {
    BModal,
    HFuseSearch,
  },
  mixins: [list],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    recipeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      recipeSearchText: '',
    };
  },
  computed: {
    isShowModal: {
      get: function() { return this.isShow; },
      set: function(newValue) { this.$emit('show', newValue); },
    },
  },
  watch: {
    isShow: function(newValue) {
      if (newValue) {
        this.fetchRecipes();
      }
    },
  },
  methods: {
    setValue(value) {
      this.recipeSearchText = value;
    },
    async fetchData(page) {
      const params = {
        limit: RECIPE_SWAP_LISTING_LIMIT,
        page,
        with: 'photo',
        'filters[query]': this.recipeSearchText,
        'filters[exclude_swappable_recipes_for]': this.recipeId,
        'filters[archived]': 0,
      };

      return recipes.search(params);
    },
    fetchRecipes() {
      this.refresh();
    },
  },
};
</script>

<style scoped>

.recipe-list-item {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #E0E5EC;
  cursor: pointer;
}

.recipe-list-item:hover {
  background-color: #F5F8FA;
}

.recipe-list-item:last-child {
  border-bottom: none;
}

.plus-icon {
  font-size: 20px;
  color: #5DBB00;
  margin-left: 10px;
}

.recipe-img {
  width: 42px;
  height: 42px;
  margin-left: 20px;
  object-fit: cover;
  border-radius: 50%;
}

.recipe-name {
  margin: 0;
  margin-left: 8px;
  color: #467FCF;
  font-size: 15px;
}
</style>
