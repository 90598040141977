import Draggable from 'vuedraggable';
import Autocomplete from '@/components/Autocomplete';
import {mainIngredients} from '@/services';
import numeral from 'numeral';

export const DEFAULT_NUMBER_OF_PEOPLE = {
  nutritions: {
    carbohydrates: 0,
    energyKCal: 0,
    energyKJ: 0,
    fat: 0,
    fibre: 0,
    protein: 0,
    salt: 0,
    saturates: 0,
    sugarCarbohydrates: 0,
    weight: 100,
  },
  portion_size: null,
  price: 0,
  quantity: null,
};

export const SERVING_SIZE = [2, 3, 4];

export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  components: {

    Draggable,
    Autocomplete,
  },
  data() {
    return {
      ingredients: [],
      ingredientsRelations: [
        'articlesForRecipes.nutritions',
        'allergens',
        'category',
      ],
    };
  },
  computed: {
    uniqueIngredients() {
      return [...new Set(this.item.ingredients.flatMap(item => item.name))];
    },
    totalPrice() {
      return numberOfPeople => {
        return numeral(this.item.ingredients
          .reduce((accumulated, current) => {
            return accumulated +
                    numeral(
                      parseFloat(current.people[numberOfPeople]?.quantity || 0) *
                        parseFloat(current.people[numberOfPeople]?.price || 0),
                    ).value();
          }, 0))
          .format('0,0.00');
      };
    },
  },
  created() {
    const params = {
      column: 'name',
      direction: 'asc',
      with: this.ingredientsRelations.join(','),
      is_active: 1,
      allowed_in_recipes: 1,
    };

    mainIngredients.getByParameters(params).then(result => this.ingredients = result);
  },
  methods: {
    createIngredient() {
      this.item.ingredients.push({
        articles: [],
        category: null,
        group: null,
        id: null,
        index: Math.max(...this.item.ingredients.map(ingredient => ingredient.index), -1) + 1,
        name: '',
        allergens: [],
        tags: [],
        people: SERVING_SIZE.reduce((accumulate, current) => {
          accumulate[current] = Object.assign({}, DEFAULT_NUMBER_OF_PEOPLE);

          return accumulate;
        }, {}),
      });
    },
    deleteIngredient(value) {
      this.$emit('ingredientRemoved', value);

      this.item.ingredients.splice(
        this.item.ingredients.findIndex(ingredient => ingredient.id === value.id && ingredient.group === value.group),
        1);
    },
    async nextIngredients(page, query) {
      const params = {

        column: 'name',
        direction: 'asc',
        page,
        with: this.ingredientsRelations.join(','),
        is_active: 1,
        allowed_in_recipes: 1,
      };

      if (query.length) params.query = query;

      const result = await mainIngredients.getByParameters(params);

      return result;
    },
    updateIngredientSelection(value, currentValue) {
      // should all have a unique portion size, prevent having a user to select duplicate portion sizes
      const uniqueArticlePortions = (value.articlesForRecipes || [])
        .filter((data, index, array) => index === array.findIndex(article => article.portion_size === data.portion_size))
        .sort((a, b) => parseInt(a.net_weight) - parseInt(b.net_weight));

      Object.assign(currentValue, {
        articles: uniqueArticlePortions,
        category: value.category,
        id: value?.id,
        name: value?.name.trim() || '',
        allergens: value.allergens,
        tags: value.tags,
        people: SERVING_SIZE.reduce((accumulate, current) => {
          accumulate[current] = Object.assign({}, DEFAULT_NUMBER_OF_PEOPLE);

          return accumulate;
        }, {}),
      });
    },
    updatePortion(value, currentValue, entry) {
      const article = entry.articles.find(article => article.portion_size === value);

      Object.assign(currentValue, {
        nutritions: article.nutrition_per_net_weight,
        portion_size: article.portion_size,
        portion_size_uom: article.portion_size_uom,
        price: article.price,
        ingredient: article.ingredient,
      });
    },
  },
};
