<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Recipe notes</h3>
    </div>
    <div class="table-responsive">
      <dimmer :active="loading">
        <table class="table card-table table-vcenter text-nowrap">
          <thead>
            <tr>
              <th></th>
              <th>User</th>
              <th>Note</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="note in notes"
              :key="note.id"
              :class="{ 'table-danger': note.deleted }">
              <td class="text-center">
                <div class="item-action dropdown">
                  <a
                    tabindex="0"
                    data-toggle="dropdown"
                    class="icon"><i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <button
                      type="button"
                      class="dropdown-item"
                      @click="deleteNote(note.id)">
                      <i class="dropdown-icon fe fe-trash"></i> Delete
                    </button>
                  </div>
                </div>
              </td>
              <td>{{note.user ? displayName(note.user) : ''}}</td>
              <td>{{note.note}}</td>
              <td>{{note.createdAt}}</td>
            </tr>
            <tr v-if="!notes.length">
              <td colspan="4">The recipe does not have any notes.</td>
            </tr>
          </tbody>
        </table>
      </dimmer>
    </div>
    <div v-if="can(uiPermissions.RECIPES_NOTES_CREATE)" class="card-footer">
      <div class="input-group">
        <textarea
          v-model="note"
          class="form-control"
          placeholder="Input recipe note"></textarea>
        <div class="input-group-append">
          <button
            :disabled="!note"
            type="button"
            class="btn btn-primary"
            @click="createNote">
            Add note
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {recipes} from '@/services';
import loadsRelations from '@hellochef/shared-js/mixins/loadsRelations';

export default {
  name: 'NotesForm',
  mixins: [
    loadsRelations,
  ],
  props: {
    recipeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      with: ['user'],
      notes: [],
      note: null,
    };
  },
  async created() {
    await this.getNotes();
  },
  methods: {
    async getNotes() {
      this.loading = true;
      try {
        const {notes} = await recipes.getNotes(this.recipeId, {params: {...this.withRelations()}});
        this.notes = notes;
      }
      finally {
        this.loading = false;
      }
    },
    async createNote() {
      try {
        await recipes.createNote(this.recipeId, {
          note: this.note,
        });
        this.note = null;
        this.$toasted.success('New note added');
      }
      finally {
        await this.getNotes();
      }
    },
    async deleteNote(id) {
      try {
        await recipes.deleteNote(this.recipeId, id);
        this.$toasted.success('Note deleted');
      }
      finally {
        await this.getNotes();
      }
    },
  },
};
</script>
