export default {

  methods: {

    escapeRegExp(str) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions

      return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string.
    },
    getWords(str) {
      str = str.trim();

      const partials = str.split(' ');

      return [

        // Exact match, case-insensitive.

        str,

        // Accent characters stripped.

        this.normalize(str),

        // Plural ending with -s.

        str.endsWith('s') ? null : str + 's',

        // Partial matches.

        partials,

        // Plural ending with -s on the last partial match.

        partials[partials.length - 1].endsWith('s') ? null : partials[partials.length - 1] + 's',
      ];
    },
    getWordsCustom(str) {
      str = str.trim();

      const ignore = ['red', 'large', 'small', 'sauce', 'brown', 'sweet', 'pepper', 'soy', 'cheese', 'grated', 'seeds', 'white', 'orange', 'cream', 'powder'];
      const whitelist = ['black pepper'];

      const partials = str.split(' ').filter(partial => whitelist.includes(str.toLowerCase()) || !ignore.includes(partial.toLowerCase()));

      return [

        // Exact match, case-insensitive.

        str,

        // Accent characters stripped.

        this.normalize(str),

        // Plural ending with -s.

        str.endsWith('s') ? null : str + 's',

        // Partial matches.

        partials,

        // Plural ending with -s on the last partial match.

        partials.length ? partials[partials.length - 1].endsWith('s') ? null : partials[partials.length - 1] + 's' : null,
      ];
    },
    html(instructions) {
      let cardItems = [];

      if (this.item.ingredients.length) {
        Object.keys(this.item.ingredients).forEach(val => cardItems.push(this.item.ingredients[val]));
        cardItems = [].concat.apply([], cardItems);

        const normalized = this.normalize(instructions);
        const words = [...new Set(cardItems.map(ingredient => [...this.getWords(ingredient.name.trim())]).flat(2).filter(word => word !== null && word.length))].sort((a, b) => b.length - a.length);

        const reg = new RegExp(`\\b(${words.map(word => this.escapeRegExp(word)).join('|')})\\b`, 'ig');
        const matches = [];

        let match;

        while ((match = reg.exec(normalized)) !== null) {
          // Assumption: the `normalized` string has the same length as the original `instructions` string and words start at the same indexes.

          matches.push({

            index: match.index,
            replacement: `<span class="font-bold text-primary word-break-break-word">${instructions.substr(match.index, match[0].length)}</span>`,
            value: match[0],
          });
        }

        matches.reverse().forEach(match => {
          instructions = instructions.substr(0, match.index) + match.replacement + instructions.substr(match.index + match.value.length);
        });
      }

      if (instructions) {
        const boldReg = new RegExp(/\[b\](.*?)\[\/b\]/, 'g');
        const boldMatches = [];
        let boldMatch;

        while ((boldMatch = boldReg.exec(instructions)) !== null) {
          boldMatches.push({
            index: boldMatch.index,
            replacement: `<span class="font-bold text-primary word-break-break-word">${instructions.substr(boldMatch.index, boldMatch[0].length)}</span>`,
            value: boldMatch[0],
          });
        }

        boldMatches.reverse().forEach(match => {
          instructions = instructions.substr(0, match.index) + match.replacement + instructions.substr(match.index + match.value.length);
        });

        const ignoreReg = new RegExp(/\[i\](.*?)\[\/i\]/, 'g');
        const ignoreTags = new RegExp(/<span class="font-bold text-primary word-break-break-word">|<\/span>/, 'g');
        const ignoreMatches = [];
        let ignoreMatch;

        while ((ignoreMatch = ignoreReg.exec(instructions)) !== null) {
          ignoreMatches.push({
            index: ignoreMatch.index,
            replacement: `[i]${ignoreMatch[1].replace(ignoreTags, '')}[/i]`,
            value: ignoreMatch[0],
          });
        }

        ignoreMatches.reverse().forEach(match => {
          instructions = instructions.substr(0, match.index) + match.replacement + instructions.substr(match.index + match.value.length);
        });

        instructions = instructions.replace(/\(spicy!\)/g, '<span class="font-bold text-danger word-break-break-word">(spicy!)</span>');
      }

      return instructions;
    },
    normalize(str) {
      return (str || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\u00A0/g, ' ');
    },
  },
};
