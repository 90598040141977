<template>
  <div class="card">
    <div class="card-header">
      <h3 v-b-tooltip="'This record shows a static snapshot of recipe metrics from four weeks prior.'" class="card-title">
        Weekly stats
      </h3>
    </div>
    <dimmer :active="!stats">
      <table class="table card-table table-hover">
        <thead>
          <tr>
            <th>Weekly menu</th>
            <th v-b-tooltip="'How many times this recipe was selected this week.'">Selections</th>
            <th v-b-tooltip="'Total number of all selections for this week.'">Total selections</th>
            <th v-b-tooltip="'Percentage of this recipe selections compared to the whole menu.'">Uptake</th>
            <th v-b-tooltip="'The predicted percentage during menu scheduling.'">Forecasted uptake</th>
            <th v-b-tooltip="'Average rate (Number of ratings)'">Ratings</th>
            <th>Quality complaints</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in stats" :key="item.id">
            <td>
              {{item.weekly_menu.startDate}} <i class="fe fe-arrow-right"></i>{{moment(item.weekly_menu.startDate).add(6, 'days').format('YYYY-MM-DD')}}
            </td>
            <td>{{item.selections}}</td>
            <td>{{item.total_selections.toLocaleString()}}</td>
            <td>{{item.uptake}}</td>
            <td>{{item.forecasted_uptake}}</td>
            <td v-b-tooltip="`${item.rating} average stars based on ${item.feedbacks} user feedbacks.`">
              {{item.rating}} <span class="small">({{item.feedbacks}})</span>
            </td>
            <td>{{item.quality_complaints}}</td>
          </tr>
        </tbody>
      </table>
    </dimmer>
  </div>
</template>

<script>
import {recipes} from '@/services';

export default {
  name: 'WeeklyMenuStats',
  props: {
    recipeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stats: null,
    };
  },
  async created() {
    this.stats = await recipes.weeklyStats(this.recipeId);
  },
};
</script>
