<template>
  <div class="list-item">
    <img
      class="recipe-img"
      :src="getTransformationUrl(recipe.photo, { width: 42, height: 42, crop: 'fill' })"
      :alt="recipe.name"/>
    <router-link
      class="recipe-link"
      :to="`/recipes/${recipe.id}`"
      target="_blank">
      <p class="recipe-name">{{recipe.name}}</p>
    </router-link>

    <div class="recipe-swap-ingredients">
      <span>{{recipe.swapFrom}}</span>
      <img
        v-svg-inline
        class="swap-arrow"
        src="@/assets/svg/arrow-left-right.svg"
        alt="recipe swap arrow"/>
      <span>{{recipe.swapTo}}</span>
    </div>

    <div v-if="can([uiPermissions.RECIPES_SWAPS_UPDATE, uiPermissions.RECIPES_SWAPS_DELETE])" class="item-action dropdown">
      <a
        tabindex="0"
        data-toggle="dropdown"
        class="icon">
        <i class="fe fe-more-vertical"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right swap-dropdown">
        <button
          v-if="can(uiPermissions.RECIPES_SWAPS_UPDATE)"
          type="button"
          class="dropdown-item edit-swap"
          @click="() => $emit('edit')">
          <img
            class="edit-swap-arrow"
            src="@/assets/svg/arrow-left-right-gray.svg"
            alt="recipe swap arrow"/> Edit Swap
          Ingredient
        </button>
        <button
          v-if="can(uiPermissions.RECIPES_SWAPS_DELETE)"
          type="button"
          class="dropdown-item delete-swap"
          @click="() => $emit('delete')">
          <i class="fe fe-trash delete-icon"></i> Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecipesSwapListItem',
  props: {
    recipe: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recipe-img {
  width: 42px;
  height: 42px;

  object-fit: cover;
  border-radius: 50%;
}

.recipe-name {
  flex: 1;
  margin: 0;
  margin-left: 12px;
  color: #467FCF;
  cursor: pointer;
}

.recipe-link {
  flex: 1;
}

.recipe-swap-ingredients {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  gap: 2px;

  height: 24px;

  background: #E8ECEF;
  border-radius: 4px;
  margin-right: 24px;
}

.recipe-swap-ingredients span {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #4A5157;
  text-transform: capitalize;
}

.swap-arrow {
  width: 12px;
  height: 12px;

  fill: #4A5157;
}

.swap-dropdown {
  padding: 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;
  gap: 8px;
  padding: 16px 12px;
  font-size: 13px;
}

.edit-swap {
  border-bottom: 1px solid #E0E5EC;
}

.edit-swap-arrow {
  width: 16px;
  height: 16px;
}

.delete-icon {
  width: 16px;
}
</style>
