<template>
  <b-modal
    v-model="isShowModal"
    size="lg"
    title="Ingredients proportionality"
    modal-class="modal-ingredients-proportionality">
    <template slot="modal-header-close"><wbr/></template>

    <div class="px-4 d-flex flex-column gap-6">
      <template v-for="(entry, index) in groupedIngredients">
        <div
          v-if="entry.type === 'group'"
          :key="`${index}-group`"
          class="d-flex justify-content-between flex-1 p-1 px-3 heading rounded">
          <strong>{{entry.value ? entry.value : '??'}}</strong>
          <div class="d-flex gap-16 justify-content-between content-row">
            <strong class="portion-size">2 ppl</strong>
            <strong class="portion-size">3 ppl</strong>
            <strong class="portion-size">4 ppl</strong>
            <strong class="portion-size">UOM</strong>
          </div>
        </div>

        <div v-else-if="entry.type === 'ingredient'" :key="`${index}-ingredient`">
          <div class="divider"></div>
          <div class="d-flex justify-content-between flex-1 px-3">
            <div>
              {{entry.value ? entry.value.name : 'NA'}}
            </div>
            <div class="d-flex gap-16 justify-content-between content-row">
              <span
                v-for="(article, people) in entry.value.people"
                :key="people"
                class="portion-size">
                {{numeral(article.portion_size * article.quantity).format('0,0[.]00')}}
              </span>
              <span class="portion-size uom">
                <!--
                  we need to show the UOM name.
                  since 2,3,4 people will have same UOM,
                  so take it from the first item in array which is for 2 people
                -->
                {{entry.value.people['2'].portion_size_uom ? entry.value.people['2'].portion_size_uom.name : '??'}}
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>

    <template slot="modal-footer">
      <button
        class="btn btn-primary"
        type="button"
        @click="handlePrint">
        Print
      </button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'ProportionalityModal',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    item: {
      required: true,
      type: Object,
    },
  },
  computed: {
    isShowModal: {
      get: function() { return this.isShow; },
      set: function(newValue) {
        this.$emit('show', newValue);
      },
    },
    groupedIngredients() {
      const result = [];
      // By defaulting to a unique value the first group will always render an editable row.
      let group = Symbol();
      let groupIndex = -1;
      this.item.ingredients.forEach(ingredient => {
        if (ingredient.group !== group) {
          groupIndex++;
          result.push({type: 'group', value: ingredient.group, groupIndex});
          group = ingredient.group;
        }
        result.push({type: 'ingredient', value: ingredient, groupIndex});
      });

      return result;
    },
  },
  methods: {
    handlePrint() {
      window.print();
    },
  },
};
</script>

<style scoped>
::v-deep .modal-ingredients-proportionality .modal-dialog {
  .heading {
    background-color: #FFE9E2;
    margin-top: 12px;

    &:first-child {
      margin-top: 0px;
    }

    &+div .divider {
      border: none !important;
    }
  }

  .divider {
    border: 0.5px solid rgba(0, 40, 100, 0.12) !important;
    margin-bottom: 6px;
  }

  .content-row {
    width: 280px;
  }

  .portion-size {
    width: 40px;
  }

  .uom {
    text-transform: lowercase;
  }
}

@media print {
  ::v-deep .modal-ingredients-proportionality .modal-dialog {
    background-color: #fff;
    max-width: 100%;
    margin: 0 !important;
    padding: 0;

    .modal-header,
    .modal-footer {
      display: none;
    }
  }
}
</style>

<style>
@media print {
  .modal-backdrop {
    background-color: #fff;
  }
}
</style>
