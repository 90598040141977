<template>

  <div class="row">

    <div class="col">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Ingredient nutritional counts</h3>
          <div class="card-options">
            <div class="d-flex align-items-center">
              <span>Portion for </span>
              <div
                class="btn-group mx-2"
                role="group"
                aria-label="Portion number">
                <button
                  v-for="portion in 4"
                  :key="portion"
                  type="button"
                  class="btn btn-secondary"
                  :class="{'active' : portionFor === portion}"
                  @click="portionFor = portion">
                  {{portion}}
                </button>
              </div>
              <span>people</span>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-outline table-vcenter card-table">
            <thead>
              <tr>
                <th></th>
                <th class="text-center">Unit</th>
                <th
                  v-for="label in nutritions"
                  :key="label"
                  class="text-center">
                  {{label}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in item.ingredients" :key="index">
                <td>
                  <router-link
                    :to="`/articles?query=${encodeURI(item.name)}`"
                    rel="noopener noreferrer"
                    target="_blank">
                    {{item.name}}
                  </router-link>
                </td>
                <td class="text-center text-nowrap ">
                  {{getPortionQuantity(item.people)}} {{(item.people[portionFor === 1 ? 2 : portionFor].portion_size_uom || {}).name}}
                </td>
                <td
                  v-for="(label, nutrition) in nutritions"
                  :key="nutrition"
                  class="text-center">
                  <span class="">{{numeral(getTotal(item.people, nutrition)).format('0,0[.]00')}}</span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2" class="text-right">Totals:</th>
                <th
                  v-for="(label, nutrition) in nutritions"
                  :key="nutrition"
                  class="text-center">
                  {{numeral(item.ingredients.map(ingredient => getTotal(ingredient.people, nutrition)).reduce((a, b) => a + b, 0)).format('0,0')}}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: {

    item: {

      required: true,
      type: Object,
    },
  },

  data() {
    return {
      nutritions: {
        energyKCal: 'Calories',
        energyKJ: 'Energy KJ',
        fat: 'Fat',
        saturates: 'Saturates',
        fibre: 'Fibre',
        protein: 'Protein',
        salt: 'Salt',
        carbohydrates: 'Carbs',
        sugarCarbohydrates: 'Sugar Carbs',
      },
      portionFor: 1,
    };
  },
  methods: {

    getPortionQuantity(item) {
      const people = item[this.portionFor];

      if (people) {
        return people.quantity * people.portion_size;
      }

      return (item[2].quantity * item[2].portion_size) / 2;
    },
    getTotal(item, nutrition) {
      const people = item[this.portionFor === 1 ? 2 : this.portionFor];
      const totalNutrition = people.nutritions[nutrition] * people.quantity;

      return this.portionFor > 1 ? totalNutrition : totalNutrition / 2;
    },
  },
};
</script>
