<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Customer profiles</h3>
    </div>
    <div class="card-body">
      <dimmer :active="saving">
        <select
          v-model="selectedProfile"
          class="form-control custom-select"
          @change="updateProfiles($event)">
          <option value="">Select profile</option>
          <option
            v-for="profile in customerProfiles"
            :key="profile.id"
            :value="profile.id">
            {{profile.name}}
          </option>
        </select>
      </dimmer>
    </div>
  </div>
</template>

<script>
import {customerProfiles, recipes} from '@/services';
export default {
  name: 'SelectCustomerProfile',
  props: {
    recipeId: {
      required: true,
    },
    recipeProfiles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      customerProfiles: [],
      selectedProfile: '',
    };
  },
  created() {
    customerProfiles
      .getByParameters()
      .then(result => (this.customerProfiles = result.items));
  },
  mounted() {
    this.selectedProfile = this.recipeProfiles[0]?.id || '';
  },
  methods: {
    async updateProfiles(event) {
      try {
        this.saving = true;
        await recipes.updateProfiles(this.recipeId, {
          customerProfiles: [event.target.value],
        });
        return this.$toasted.success('Customer profiles updated.');
      }
      catch (e) {
        console.error(e.response);
        return this.$toasted.error('Could not save customer profiles.');
      }
      finally {
        this.saving = false;
      }
    },
  },
};
</script>
