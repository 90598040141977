<template>
  <div class="row">
    <div class="col">
      <div v-if="showComment">
        <review-comment
          :initialComment="selectedItem.notes"
          @cancel="cancelComment"
          @submit="submitComment($event)"
        />
      </div>
      <div v-else class="card ingredient-review-container">
        <div class="card-header">
          <h3>Ingredients</h3>
          <div class="card-options">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="create">
              Add New
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
            <tr>
              <th>
                <input
                  v-model="item.groupName"
                  type="text"
                  class="form-control w-auto d-inline-block"
                  @input="event => updateGroupName(event.target.value)"
                />
                <button
                  v-if="item.type === 'edit'"
                  class="btn btn-link"
                  type="button"
                  @click="createComment(item)"
                >
                  Add comment
                </button>
              </th>
            </tr>
            <draggable
              v-model="selectedIngredients"
            >
              <tr
                v-for="(selectedIngredient, index) in selectedIngredients"
                :key="`${index}-group`"
              >
                <td :class="selectedIngredient.type === 'new' ? 'align-top' : ''">
                  <button
                    v-if="selectedIngredient.type === 'new'"
                    type="button"
                    class="btn btn-link"
                    @click="createComment(selectedIngredient)"
                  >
                    Add comment
                  </button>
                  <div class="d-flex align-items-center">
                    <autocomplete
                      :list.sync="ingredients"
                      :nextPage="nextIngredients"
                      :value="selectedIngredient"
                      :clearable="false"
                      :disabled="selectedIngredient.type !== 'new'"
                      label="name"
                      entity="ingredient"
                      @input="value => updateReviewIngredientSelection(value, selectedIngredient)"/>
                  </div>
                </td>

                <td
                  v-for="(article, people) in selectedIngredient.people"
                  :key="people"
                  class="align-top">
                  <div class="comment-container">
                    <button
                      v-if="article.edit_portion_size || article.edit_quantity"
                      type="button"
                      class="btn btn-link"
                      @click="createComment(article)"
                    >
                      Add comment
                    </button>
                  </div>
                  <div class="input-group">
                    <select
                      v-model="article.quantity"
                      class="form-control custom-select"
                      required
                      @input="updateQuantity($event.target.value, article, selectedIngredient)"
                    >
                      <option :value="null">Quantity</option>
                      <option
                        v-for="range in 15"
                        :key="range"
                        :value="range">
                        {{range}}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <select
                        v-model="article.portion_size"
                        class="form-control custom-select"
                        required
                        @input="updatePortionReview($event.target.value, article, selectedIngredient)">
                        <option :value="null">Select portion</option>
                        <option
                          v-for="purchasable in selectedIngredient.articles"
                          :key="purchasable.id"
                          :value="purchasable.portion_size">
                          {{numeral(purchasable.portion_size).format('0,0[.]00')}} {{(purchasable.portion_size_uom || {}).name}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="text-right p-2">
                    <span class="text-muted">AED {{numeral(parseFloat(article.quantity) * parseFloat(article.price)).format('0,0.00')}}</span>
                  </div>
                </td>
                <td class="text-center">
                  <div class="item-action dropdown">
                    <a
                      tabindex="0"
                      data-toggle="dropdown"
                      class="icon"><i class="fe fe-more-vertical"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <button
                        type="button"
                        class="dropdown-item"
                        @click="deleteIngredient(selectedIngredient, index)">
                        <i class="dropdown-icon fe fe-trash"></i> Delete
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <i class="fe fe-move cursor-pointer"></i>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <div class="footer">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-danger mr-4"
              @click="$emit('cancel')"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary mr-4"
              @click="submit"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ingredientFormEdit from '@/mixins/ingredientFormEdit';
import ReviewComment from '@/components/formReview/ReviewComment';
import keyBy from 'lodash/keyBy';

export default {
  components: {

    ReviewComment,
  },
  mixins: [ingredientFormEdit],
  props: {
    initialIngredients: {
      reuired: true,
      type: Array,
    },
    group: {
      reuired: true,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showComment: false,
      selectedItem: {},
    };
  },
  computed: {
    selectedIngredients: {
      get() {
        return this.item.ingredients.filter(ingredient => ingredient.type !== 'deleted');
      },
      set(sortedIngredients) {
        let index = 0;
        sortedIngredients.forEach(ingredient => ingredient.index = index++);

        this.item.ingredients.sort((a, b) => a.index - b.index);
      },
    },
  },
  methods: {
    create() {
      this.createIngredient();
      const lastIndex = this.item.ingredients.length - 1;
      this.item.ingredients[lastIndex].type = 'new';
      this.item.ingredients[lastIndex].group = this.group.index;
    },
    createComment(item) {
      this.selectedItem = item;
      this.selectedItem.notes = this.selectedItem.notes || '';
      this.showComment = true;
    },
    updateReviewIngredientSelection(value, selectedIngredient) {
      this.updateIngredientSelection(value, selectedIngredient);

      // Check if the ingredient was alrady there, but deleted
      const existingIngredient = this.item.ingredients.find(ingredient => {
        return ingredient.type === 'deleted' && ingredient.id === selectedIngredient.id;
      });

      if (existingIngredient) {
        // Remove the new ingredient record
        this.item.ingredients.splice(this.item.ingredients.findIndex(
          ingredient => ingredient.id === selectedIngredient.id && ingredient.type === 'new',
        ), 1);

        // show the old deleted ingredient
        delete existingIngredient.type;
      }
    },
    deleteIngredient(value) {
      const ingredientIndex = this.item.ingredients.findIndex(ingredient => ingredient.id === value.id);

      // if the ingredient was newly created, then it should be removed from the ingredients array
      if (this.item.ingredients[ingredientIndex].type === 'new') {
        this.item.ingredients.splice(ingredientIndex, 1);
      }
      else {
        this.item.ingredients.splice(ingredientIndex, 1, {...this.item.ingredients[ingredientIndex], type: 'deleted'});
        this.showComment = true;
        this.selectedItem = this.item.ingredients[ingredientIndex];
      }
    },
    trackSortedIngredients() {
      const newIngredients = keyBy(this.item.ingredients, 'id');

      const filteredIngredients = this.item.ingredients.filter(ingredient => {
        return ingredient.type !== 'new' && ingredient.type !== 'deleted';
      });

      this.initialIngredients.filter(({id}) => newIngredients[id]?.type !== 'deleted')
        .forEach((ingredient, index) => {
          if (filteredIngredients[index] && ingredient.id !== filteredIngredients[index].id) {
            filteredIngredients[index].type = 'sorted';
          }
          else {
            delete filteredIngredients[index]?.type;
          }
        });
    },
    cancelComment() {
      this.showComment = false;
    },
    submitComment(comment) {
      this.selectedItem.notes = comment;
      this.showComment = false;
    },
    updateGroupName(value) {
      if (value !== this.group.groupName) {
        this.item.type = 'edit';
      }
      else {
        delete this.item.type;
      }
    },
    updateQuantity(value, article, selectedIngredient) {
      if (selectedIngredient.type === 'new') {
        return;
      }

      const baseIngredient = this.initialIngredients.find(initialIngredient => initialIngredient.id === selectedIngredient.id);

      if (!baseIngredient) {
        return;
      }

      const initialArticle = baseIngredient.people.find(item => item.people === article.people);

      if (parseInt(value) !== parseInt(initialArticle.quantity)) {
        article.edit_quantity = true;
      }
      else {
        delete article.edit_quantity;
      }
      article.quantity = value;
    },
    updatePortionReview(value, article, selectedIngredient) {
      this.updatePortion(value, article, selectedIngredient);

      if (selectedIngredient.type === 'new') {
        return;
      }

      const baseIngredient = this.initialIngredients.find(initialIngredient => initialIngredient.id === selectedIngredient.id);

      if (!baseIngredient) {
        return;
      }

      const initialArticle = baseIngredient.people.find(item => item.people === article.people);

      if (value !== initialArticle.portion_size) {
        article.edit_portion_size = 'true';
      }
      else {
        delete article.edit_portion_size;
      }
    },
    submit() {
      this.trackSortedIngredients();
      this.$emit('submit');
    },
  },
};
</script>

<style scoped>
    .ingredient-review-container {
        max-height: 90vh;
        overflow-y: scroll;
    }
    .comment-container {
        height: 38px;
    }
</style>
