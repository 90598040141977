export const recipeStatusesList = [{
  id: 1,
  value: 'in-development',
  name: 'In Development',
}, {
  id: 2,
  value: 'peer-test',
  name: 'Peer Test',
}, {
  id: 3,
  value: 'in-review',
  name: 'In Review',
}, {
  id: 4,
  value: 'review-completed',
  name: 'Review Completed',
}, {
  id: 5,
  value: 'ready-to-be-drafted',
  name: 'Ready to be drafted',
}, {
  id: 6,
  value: 'published-on-menu',
  name: 'Published on Menu',
}, {
  id: 7,
  value: 'archived',
  name: 'Archived',
}];
