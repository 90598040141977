<template>
  <div class="card">
    <div class="card-header">
      <h3>Comment</h3>
    </div>
    <div class="card-body">
      <textarea
        v-model="comment"
        class="w-100 form-control"
        rows="8"/>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-danger mr-4"
          @click="cancel"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-success mr-4"
          @click="submit"
        >
          Save comment
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    initialComment: String,
  },
  data() {
    return {
      comment: this.initialComment,
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit() {
      this.$emit('submit', this.comment);
    },
  },
};
</script>
