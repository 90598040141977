<template>
  <div>
    <carousel
      v-if="reviews.length > 2"
      ref="carousel"
      :perPage="2"
      :navigationEnabled="true"
    >
      <slide
        v-for="(review, index) in reviews"
        :key="index"
      >
        <review-editor
          :viewOnly="viewOnly"
          :review="review"
          :reviewkey="index"
          @submit="submit"
          @accepted="$emit('accept', review)"
          @rejected="$emit('reject', review)"
        />
      </slide>
    </carousel>
    <div v-else class="review-container">
      <div
        v-for="(review, index) in reviews"
        :key="index"
        class="review">
        <review-editor
          :viewOnly="viewOnly"
          :review="review"
          :reviewkey="index"
          @submit="submit"
          @accepted="$emit('accept', review)"
          @rejected="$emit('reject', review)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ReviewEditor from './ReviewEditor.vue';
import {Carousel, Slide} from 'vue-carousel';

export default {
  components: {

    ReviewEditor,
    Carousel,
    Slide,

  },
  props: {
    viewOnly: {
      type: Boolean,
      default: false,
    },
    reviews: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    submit({key, changes, notes}) {
      this.reviews[key].changes = changes;
      this.reviews[key].notes = notes;
      this.$emit('submit');
    },
  },
};

</script>

<style scoped>
    .review-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .review-container .review {
      min-width: 500px;
    }

</style>
