<template>
  <div class="card swap-container">
    <!-- Header -->
    <div class="card-header header-container">
      <h3 class="card-title">Recipe Swap</h3>
    </div>
    <!-- Swap Items -->
    <div v-for="swap in swaps" :key="swap.id">
      <recipes-swap-list-item
        :recipe="swap"
        class="list-item"
        @edit="() => $emit('edit', swap)"
        @delete="() => $emit('delete', swap.id, swap.swapFromId)"/>
    </div>
    <!-- Action -->
    <div v-if="can(uiPermissions.RECIPES_SWAPS_CREATE)" class="action">
      <i class="fe fe-plus plus-icon"/>
      <p
        class="action-text"
        data-test="add-swap-btn"
        @click="() => $emit('trigger-add-swap')">
        Add Swap Recipe
      </p>
    </div>
  </div>
</template>

<script>
import RecipesSwapListItem from '@/components/view/recipes/recipes-swap-list-item';

export default {
  name: 'RecipesSwapList',
  components: {
    RecipesSwapListItem,
  },
  props: {
    swaps: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card.swap-container {
  flex-grow: unset;
}

.list-item {
  padding: 12px;
  padding-left: 20px;
  padding-right: 28px;
  border-bottom: 1px solid rgba(0, 40, 100, 0.12);
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 32px;
  color: #467FCF;
  width: fit-content;
  cursor: pointer;
}

.action-text {
  margin: 0;
}

.plus-icon {
  font-size: 20px;
}
</style>
