<template>
  <div>
    <div v-if="showServingSize" class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <label for="servingSize" class="form-label">Serving size</label>
            <div class="input-group">
              <input
                v-model="item.market_item.serving_size"
                class="form-control"
                type="number"
                step="1"
                readonly
                disabled
                id="servingSize"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <modal :visible="reviewModalKey ? true : false" @close="reviewModalKey = null">
        <review-container
          v-if="reviewModalKey"
          :reviews="newReviewIngredient || reviews[reviewModalKey]"
          :viewOnly="true"
          @submit="reviewModalKey = null"
          @accept="acceptReview"
          @reject="rejectReview"
        />
      </modal>
      <modal
        :visible="selectedReviewGroup ? true : false"
        :withCloseIcon="false"
        @close="selectedReviewGroup = null">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-11">
              <ingredients-review
                :item="newReview"
                :initialIngredients="selectedReviewIngredients"
                :group="selectedReviewGroup || {}"
                @cancel="selectedReviewGroup = null"
                @submit="submitReview"
              />
            </div>
          </div>
        </div>
      </modal>
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Articles</h3>
            <div v-if="!externalReview" class="card-options">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="createIngredient">
                Add new
              </button>
              <button
                type="button"
                class="btn btn-secondary btn-sm ml-2"
                @click="sortIngredients">
                Sort
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th></th>
                  <th>2 people (AED {{totalPrice(2)}})</th>
                  <th>3 people (AED {{totalPrice(3)}})</th>
                  <th>4 people (AED {{totalPrice(4)}})</th>
                  <th></th>
                  <th class="w-1"></th>
                </tr>
              </thead>
              <draggable
                v-model="groupedIngredients"
                :disabled="externalReview"
                element="tbody"
                :options="{handle: '.fe-move'}">
                <template v-for="(entry, index) in groupedIngredients">
                  <tr v-if="entry.type === 'group'" :key="`${index}-group`">
                    <th></th>
                    <th>
                      <div
                        :class="{highlighted : reviews[`ingredients.group.${entry.groupIndex}`] ? true : false}"
                        @click="reviews[`ingredients.group.${entry.groupIndex}`] && handleIngredientReview(`ingredients.group.${entry.groupIndex}`)"
                      >
                        <input
                          :readonly="externalReview"
                          type="text"
                          class="form-control w-auto"
                          :value="entry.value"
                          @input="setGroupName($event.target.value, entry.value)"/>
                      </div>
                    </th>
                    <th v-if="externalReview" :colspan="7">
                      <button
                        type="button"
                        class="btn btn-link"
                        @click="editGroup(entry.value, entry.groupIndex)"
                      >
                        Edit Ingredient Group
                      </button>
                    </th>
                    <th v-else :colspan="7"></th>
                  </tr>
                  <tr
                    v-else-if="entry.type === 'ingredient'"
                    :key="`${index}-ingredient`"
                    :class="{
                      'new-ingredient': entry.value.type === 'new',
                      'deleted-ingredient': reviews[`ingredients.deleted.${entry.groupIndex}.${entry.value.id}`] ? true : false,
                    }"
                    @click="
                      (entry.value.type === 'new' || reviews[`ingredients.deleted.${entry.groupIndex}.${entry.value.id}`]) &&
                        handleIngredientReview(entry.value.type === 'new' ?
                          `ingredients.new.${entry.groupIndex}.${entry.value.id}` :
                          `ingredients.deleted.${entry.groupIndex}.${entry.value.id}`, entry.value.reviewer_id)
                    "

                  >
                    <td class="align-top pt-4 px-4">{{entry.value.index + 1}}</td>
                    <td class="align-top">
                      <div class="d-flex align-items-center">
                        <autocomplete
                          :list.sync="ingredients"
                          :nextPage="nextIngredients"
                          :value="entry.value"
                          :clearable="false"
                          :disabled="!can(uiPermissions.RECIPES_UPDATE) || item.archived || item.is_in_published_menu || externalReview"
                          label="name"
                          entity="ingredient"
                          @input="value => updateIngredientSelection(value, entry.value)"/>
                        <i
                          v-if="entry.value.allergens && entry.value.allergens.length"
                          v-b-tooltip="`Allergens: ${entry.value.allergens.map(allergen => allergen.name).join(', ')}`"
                          class="ml-2 text-warning fe fe-alert-circle"/>
                        <i
                          v-if="entry.value.tags && entry.value.tags.length"
                          v-b-tooltip="`Tags: ${entry.value.tags.map(tag => tag.name).join(', ')}`"
                          class="ml-2 text-warning fe fe-tag"/>
                        <router-link
                          v-if="!externalReview"
                          :to="`/articles?query=${encodeURI(entry.value.name)}`"
                          target="_blank"
                          class="fe fe-external-link ml-2"></router-link>
                      </div>
                    </td>
                    <td
                      v-for="(article, people) in entry.value.people"
                      :key="people"
                      class="align-top">
                      <div class="input-group">
                        <div
                          :class="{highlighted : reviews[`ingredients.${entry.groupIndex}.${entry.value.id}.people.${article.people}.quantity`] ? true : false}"
                          @click="
                            internalReview &&
                              reviews[`ingredients.${entry.groupIndex}.${entry.value.id}.people.${article.people}.quantity`] &&
                              handleIngredientReview(`ingredients.${entry.groupIndex}.${entry.value.id}.people.${article.people}.quantity`)">
                          <select
                            v-model="article.quantity"
                            :disabled="externalReview || reviews[`ingredients.${entry.groupIndex}.${entry.value.id}.people.${article.people}.quantity`]"
                            force-disable
                            class="form-control custom-select quantity-select"
                            required
                          >
                            <option :value="null">Select quantity</option>
                            <option
                              v-for="range in 15"
                              :key="range"
                              :value="range">
                              {{range}}
                            </option>
                          </select>
                        </div>
                        <div class="input-group-append">
                          <div
                            :class="{highlighted : reviews[`ingredients.${entry.groupIndex}.${entry.value.id}.people.${article.people}.portion_size`] ? true : false}"
                            @click="
                              internalReview &&
                                reviews[`ingredients.${entry.groupIndex}.${entry.value.id}.people.${article.people}.portion_size`] &&
                                handleIngredientReview(`ingredients.${entry.groupIndex}.${entry.value.id}.people.${article.people}.portion_size`)"
                          >
                            <select
                              v-model="article.portion_size"
                              :disabled="externalReview || reviews[`ingredients.${entry.groupIndex}.${entry.value.id}.people.${article.people}.portion_size`]"
                              force-disable
                              class="form-control custom-select portion-select"
                              required
                              @input="updatePortion($event.target.value, article, entry.value)">
                              <option :value="null">Select portion</option>
                              <option
                                v-for="purchasable in entry.value.articles"
                                :key="purchasable.id"
                                :value="purchasable.portion_size">
                                {{numeral(purchasable.portion_size).format('0,0[.]00')}} {{(purchasable.portion_size_uom || {}).name}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="text-right p-2">
                        <span class="text-muted">AED {{numeral(parseFloat(article.quantity) * parseFloat(article.price)).format('0,0.00')}}</span>
                      </div>
                    </td>
                    <td v-if="!externalReview" class="text-center">
                      <div class="item-action dropdown">
                        <a
                          tabindex="0"
                          data-toggle="dropdown"
                          class="icon"><i class="fe fe-more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <button
                            type="button"
                            class="dropdown-item"
                            @click="deleteIngredient(entry.value, index)">
                            <i class="dropdown-icon fe fe-trash"></i> Delete
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <i class="fe fe-move cursor-pointer"></i>
                    </td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Draggable from 'vuedraggable';
import Autocomplete from '@/components/Autocomplete';
import {mainIngredients} from '@/services';
import highlighter from '@/mixins/highlighter';
import numeral from 'numeral';
import IngredientsReview from '@/components/formReview/IngredientsReview';
import map from 'lodash/map';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import set from 'lodash/set';
import get from 'lodash/get';
import ReviewContainer from '@/components/formReview/ReviewContainer';
import ingredientFormEdit from '@/mixins/ingredientFormEdit';

const PRIORITIZED_CATEGORIES = ['meat', 'poultry', 'fish'];

export default {

  components: {
    Autocomplete,
    Draggable,
    IngredientsReview,
    ReviewContainer,
  },
  mixins: [

    ingredientFormEdit,
    highlighter,
  ],
  props: {
    item: {
      required: true,
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    externalReview: {
      type: Boolean,
      default: false,
    },
    internalReview: {
      type: Boolean,
      default: false,
    },
    reviews: {
      type: Object,
      default: () => ({
        ingredients: {},
      }),
    },
    currentReviews: {
      type: Object,
      default: () => ({
        ingredients: {},
      }),
    },
    showServingSize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

      articleModalShow: false,
      ingredients: [],
      ingredientsRelations: [
        'articlesForRecipes.nutritions',
        'allergens',
        'category',
        'tags.category',
      ],
      ingredientGroups: [],
      selectedReviewGroup: null,
      selectedReviewIngredients: [],
      newReview: {
        groupName: '',
        ingredients: [],
      },
      newReviewIngredient: null,
      reviewModalKey: null,
    };
  },
  computed: {
    groupedIngredientsById() {
      return {
        ingredients: map(
          groupBy(this.item.ingredients, ({group}) => this.ingredientGroups.indexOf(group)),
          groupedIngredients => keyBy(groupedIngredients, 'id'),
        ),
      };
    },
    groupedIngredients: {

      get() {
        const result = [];
        let group = Symbol(); // By defaulting to a unique value the first group will always render an editable row.
        let groupIndex = -1;
        this.item.ingredients.forEach(ingredient => {
          if (ingredient.group !== group) {
            groupIndex++;
            result.push({type: 'group', value: ingredient.group, groupIndex});
            group = ingredient.group;
            this.ingredientGroups[groupIndex] = group;
          }

          result.push({type: 'ingredient', value: ingredient, groupIndex});
        });

        return result;
      },
      set(sortedItems) {
        // This setter should only be called by the Draggable component after reordering the list.

        let index = 0;
        let group = null;

        sortedItems.forEach(entry => {
          if (entry.type === 'group') {
            group = entry.value;
          }
          else {
            entry.value.index = index++;
            entry.value.group = group;
          }
        });

        this.item.ingredients.sort((a, b) => a.index - b.index);
      },
    },
    uniqueIngredients() {
      return [...new Set(this.item.ingredients.flatMap(item => item.name))];
    },
    totalPrice() {
      return numberOfPeople => {
        return numeral(this.item.ingredients
          .reduce((accumulated, current) => {
            return accumulated +
              numeral(
                parseFloat(current.people[numberOfPeople]?.quantity || 0) *
                parseFloat(current.people[numberOfPeople]?.price || 0),
              ).value();
          }, 0))
          .format('0,0.00');
      };
    },
  },
  watch: {
    reviews: function() {
      // sort the ingredients after receiving the reviews
      this.sortIngredients();
      this.ingredientGroups = [];
    },
  },
  created() {
    const params = {
      column: 'name',
      direction: 'asc',
      with: this.ingredientsRelations.join(','),
      is_active: 1,
      allowed_in_recipes: 1,
    };

    mainIngredients.getByParameters(params).then(result => this.ingredients = result);
  },
  methods: {
    acceptReview(review) {
      if (review.action === 'deleted') {
        this.deleteIngredient(get(this.groupedIngredientsById, `ingredients.${review.changes}`, {}));
      }
      else if (review.action === 'new') {
        this.item.ingredients = this.item.ingredients.filter(ingredient => {
          // remove the same new ingredient that were created by different reviewers for the same group
          if (ingredient.id === review.changes.id &&
                this.ingredientGroups.indexOf(ingredient.group) === review.changes.group &&
                  ingredient.reviewer_id !== review.reviewer_id
          ) {
            return false;
          }
          return true;
        });

        delete this.groupedIngredientsById.ingredients?.[review.changes.group]?.[review.changes.id]?.type;
        this.newReviewIngredient = null;
      }
      else if (review.key.startsWith('ingredients.group')) {
        // The review key in that case will be for example 'ingredients.group.1'
        // The groupIndex in that case will be qual to 1
        const groupIndex = review.key.split('.').pop();

        // We need to find the current group that has the same index
        const group = this.groupedIngredients.find(group => {
          return group.type === 'group' && parseInt(group.groupIndex) === parseInt(groupIndex);
        });

        if (group) {
          this.setGroupName(review.changes, group.value);
        }
      }
      else {
        set(this.groupedIngredientsById, review.key, review.changes);
      }

      delete this.reviews[review.key];
      this.reviewModalKey = null;
    },
    rejectReview(review) {
      // If the user rejected the new ingredient - then it should be removed
      if (review.action === 'new') {
        this.item.ingredients.splice(this.item.ingredients.findIndex(ingredient => (
          ingredient.id === review?.changes?.id &&
            this.ingredientGroups.indexOf(ingredient.group) === review?.changes?.group &&
              ingredient.reviewer_id === review.reviewer_id
        )), 1);

        this.newReviewIngredient = null;
        this.reviewModalKey = null;
      }

      this.removeReview(review);
    },
    removeReview(review) {
      // check if review exists in reviews object
      if (!this.reviews[review.key]) {
        this.reviewModalKey = null;
        return;
      }

      this.reviews[review.key] = this.reviews[review.key].filter(({reviewer_id}) => review.reviewer_id !== reviewer_id);

      if (this.reviews[review.key].length === 0) {
        delete this.reviews[review.key];
        this.reviewModalKey = null;
      }
    },
    handleIngredientReview(key, reviewerId = null) {
      if (key.startsWith('ingredients.new') && this.reviews[key]) {
        this.newReviewIngredient = [this.reviews[key].find(review => review.reviewer_id === reviewerId)];
      }

      this.reviewModalKey = key;
    },
    setGroupName(value, oldValue) {
      this.item.ingredients.forEach(ingredient => {
        if (ingredient.group === oldValue) { ingredient.group = value; }
      });
    },
    editGroup(groupName, groupIndex) {
      this.selectedReviewGroup = {groupName, index: groupIndex};

      this.selectedReviewIngredients = this.item.ingredients.filter(ingredient => ingredient.group === groupName)
        .map(({id, index, people, name, articles}) => {
          return {
            id,
            index,
            name,
            articles: articles.map(({id, name, portion_size, portion_size_uom, price}) => ({
              id, name, portion_size, price, portion_size_uom: pick(portion_size_uom, ['id', 'name']),
            })),
            people: map(people, ({people, portion_size, price, quantity, portion_size_uom}) => {
              return {
                people,
                portion_size,
                price,
                quantity,
                portion_size_uom: portion_size_uom ? pick(portion_size_uom, ['id', 'name']) : {},
              };
            }),
          };
        });

      if (this.currentReviews[`group.${groupIndex}`]) {
        this.newReview = cloneDeep(this.currentReviews[`group.${groupIndex}`]);
      }
      else {
        this.newReview.ingredients = cloneDeep(this.selectedReviewIngredients);
        this.newReview.groupName = groupName;
        this.newReview.key = `group.${groupIndex}`;
      }
    },
    submitReview() {
      this.newReview.ingredients = this.newReview.ingredients.filter(({id}) => !!id);

      if (JSON.stringify({ingredients: this.newReview.ingredients, groupName: this.newReview.groupName}) ===
      JSON.stringify({ingredients: this.selectedReviewIngredients, groupName: this.selectedReviewGroup.groupName})) {
        delete this.currentReviews[`group.${this.selectedReviewGroup.index}`];
      }
      else {
        this.currentReviews[`group.${this.selectedReviewGroup.index}`] = cloneDeep(this.newReview);
      }

      this.selectedReviewGroup = null;
      this.newReview = {
        groupName: '',
        ingredients: [],
      };
    },
    sortIngredients() {
      let ingredientOrders = [];

      this.uniqueIngredients.forEach(item => {
        const specialWords = {
          'soy sauce': 'sweet',
        };

        const ignoreItem = specialWords[item.toLowerCase()] || '';

        const instructions = this.item.steps.reduce((a, step) => a + step.instructions_list, '');

        const normalized = this.normalize(instructions);
        const words = [...new Set(this.getWordsCustom(item).flat(2).filter(word => word !== null).sort((a, b) => b.length - a.length))];

        let reg = new RegExp(`\\b(${words.map(word => this.escapeRegExp(word)).join('|')})\\b`, 'gi');

        if (ignoreItem) { reg = new RegExp(`(?<!${ignoreItem} )\\b(${words.map(word => this.escapeRegExp(word)).join('|')})\\b`, 'gi'); }

        let matched;

        while ((matched = reg.exec(normalized)) !== null) {
          ingredientOrders.push({
            index: matched.index,
            word: item,
          });
        }
      });

      ingredientOrders = [...new Set(ingredientOrders.sort((a, b) => a.index - b.index).map(order => order.word))];

      const groups = this.groupedIngredients.filter(group => group.type === 'group');
      const newOrder = [];

      groups.forEach(group => {
        newOrder.push(group);

        const sortedGroup = this.groupedIngredients
          .filter(entry => entry.type === 'ingredient' && entry.value.group === group.value)
          .sort((a, b) => {
            const orderA = ingredientOrders.findIndex(name => name === a.value.name);
            const orderB = ingredientOrders.findIndex(name => name === b.value.name);

            // this categories are prioritized to be first on any order
            if (PRIORITIZED_CATEGORIES.includes((a.value?.category?.name || '').toLowerCase())) return -1;
            if (PRIORITIZED_CATEGORIES.includes((b.value?.category?.name || '').toLowerCase())) return 1;

            if (orderA === orderB) return 0;
            if (orderA === -1) return 1;
            if (orderB === -1) return -1;

            return orderA < orderB ? -1 : 1;
          });

        newOrder.push(...sortedGroup);
      });

      this.groupedIngredients = newOrder;
    },
  },
};

</script>

<style scoped>

    /* prevents dropdown cutting of from the table in desktop */
    @media (min-width: 1023px) {
        .table-responsive {
            overflow: visible;
        }
    }

    .highlighted > select {
      background-color: #E0E325;
      color: black;
    }

    .quantity-select {
      max-width: 70px;
    }

    .portion-select {
      max-width: 100px;
    }

    .new-ingredient, .new-ingredient:hover {
      background-color: #DFFFB0;
    }

    .deleted-ingredient, .deleted-ingredient:hover {
      background-color: #FFD8C9;
    }

</style>
