<template>
  <b-modal
    v-model="showModal"
    centered
    title="Customize mobile thumbnail"
    size="lg">
    <template slot="modal-header-close"><wbr></template>
    <div class="row">
      <div class="col-12 w-100 mb-4">
        <p class="h4">Full image</p>
        <vue-cropper
          ref="cropper"
          :aspect-ratio="0.9"
          :auto-crop-area="1"
          :src="photo.url"
          preview=".cropped-preview"
        />
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <p class="h4">Mobile preview</p>
            <div v-b-tooltip.right="'This is a preview of how it would look like if you saved the crop.'" class="preview cropped-preview"/>
          </div>
          <div v-if="thumbnail" class="col-6">
            <p class="h4 float-right">Current thumbnail</p>
            <div class="preview float-right">
              <img
                v-b-tooltip.leftbottom="'This is the mobile thumbnail currently in display.'"
                :src="thumbnail.url"
                style="width: 100%"
                alt="thumbnail">
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <button
        :disabled="submitting"
        class="btn btn-primary"
        @click.prevent="showModal=false">
        Cancel
      </button>
      <button
        v-b-tooltip="'Reset cropping to center'"
        :disabled="submitting"
        class="btn btn-primary"
        @click.prevent="reset">
        Reset
      </button>
      <button
        v-b-tooltip="'Save cropped thumbnail'"
        :disabled="submitting"
        class="btn btn-success"
        @click.prevent="cropImage">
        Crop
      </button>
    </template>
  </b-modal>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import modal from '@/mixins/modal';
import {images, recipes} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';

export default {
  name: 'ThumbnailCropper',
  components: {
    VueCropper,
  },
  mixins: [
    modal,
    submitting,
  ],
  props: {
    recipeId: {
      type: Number,
      required: true,
    },
    photo: {
      type: Object,
      required: true,
    },
    thumbnail: {
      type: Object,
      required: false,
    },
  },
  methods: {
    reset() {
      this.$refs.cropper.reset();
    },
    /** https://stackoverflow.com/a/38935990/6714319 */
    toImage(dataUrl) {
      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], 'photo', {type: mime});
    },
    cropImage() {
      const crop = this.$refs.cropper.getCroppedCanvas().toDataURL();

      this.submitIt(async () => {
        try {
          const {item} = await images.save(this.toImage(crop), {
            tags: ['recipe', 'cropped plated shot'],
          });

          await recipes.updateThumbnail(this.recipeId, {
            photo_id: item.id,
          });

          this.$emit('cropped', item);
          this.showModal = false;

          this.$toasted.success('New thumbnail uploaded and saved.');
        }
        catch (e) {
          this.$toasted.error(`Cropping error: ${e}`);
        }
      });
    },
  },
};
</script>

<style scoped>
.cropped-preview {
  width: 100%;
  height: calc(372px * 0.9);
}

.preview {
  width: 301px;
  overflow: hidden;
  border-radius: 10px;
  border: gray solid 1px;
}
</style>
