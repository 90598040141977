<template>

  <component
    :is="value ? 'a' : 'div'"
    :href="value ? value.url : false"
    :target="value ? '_blank' : false"
    :rel="value ? 'noopener noreferrer' : false"
    class="d-flex align-items-center justify-content-center flex-shrink-0"
    :class="{[$style.filled]: value}"
    :style="value ? `background-image: url(${thumbnail});` : false">
    <button
      v-if="!value"
      type="button"
      class="btn btn-icon btn-secondary"
      :disabled="submitting"
      @click.prevent="$refs.fileInput.click()">
      <i class="fe fe-upload"></i>
    </button>
    <button
      v-else
      type="button"
      class="btn btn-icon btn-danger"
      :disabled="submitting"
      @click.prevent="removePhoto">
      <i class="fe fe-trash"></i>
    </button>
    <input
      ref="fileInput"
      type="file"
      accept="image/*"
      hidden
      @change="handleFileChange"/>
  </component>

</template>

<script>

import {images} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';
import $axios from 'axios';

export default {
  mixins: [
    submitting,
  ],
  props: {
    options: {
      type: [Array, Object],
      default: null,
    },
    tags: {
      type: Array,
      default: () => { return []; },
    },
    thumbnailOptions: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [Object, Number],
      default: null,
    },
    preview: {
      type: Object,
      default: null,
    },
    landscapeOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    thumbnail() {
      if (this.preview?.url) {
        return this.preview.url;
      }
      return this.value ? this.getTransformationUrl(this.value, this.thumbnailOptions) : '';
    },
  },
  mounted() {
    if (this.tags.includes('recipe') && this.thumbnail) {
      $axios.get(this.thumbnail).catch(e => {
        this.$emit('error', new Error('Some images are missing'));
      });
    }
  },
  methods: {
    async handleFileChange(e) {
      try {
        if (e.target.files.length > 0) {
          const file = e.target.files[0];

          if (file.size > 10 * 1000000) { // 10 MB
            this.$emit('error', new Error('File too large. Images cannot exceed 10 MB.'));
            return;
          }

          if (this.landscapeOnly) {
            await this.checkIfLandScape(file);
          }

          this.submitIt(async () => {
            try {
              const {item} = await images.save(file, {

                options: this.options,
                tags: this.tags,
              });

              this.$emit('input', item);
            }
            finally {
              e.target.value = null;
            }
          });
        }
      }
      catch (ex) {
        this.$emit('error', ex);
      }
      finally {
        e.target.value = null;
      }
    },
    removePhoto() {
      // reset the photo object to blank
      if (window.confirm('Are you sure that you want to remove the photo?')) this.$emit('input', null);
    },
    checkIfLandScape(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();

          // Read the contents of Image File.
          reader.readAsDataURL(file);
          reader.onload = e => {
            // Initiate the JavaScript Image object.
            const image = new Image();

            // Set the Base64 string return from FileReader as source.
            image.src = e.target.result;

            // Validate if image is landscape
            image.onload = imageEvent => {
              const target = imageEvent.target;

              if (target.width < target.height) {
                reject(new Error('Image orientation must be landscape'));
              }

              resolve();
            };
          };
        }
        catch (ex) {
          reject(ex);
        }
      });
    },
  },
};

</script>

<style module>

    .filled:not(:hover) > * {display: none;}

</style>
